/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, jsx } from "theme-ui";
import ContentWithImage from "../../components/ContentWithImage";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const ShingleRoofs = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="Shingle Roofs"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerBottomIconAlt="Icon with blue buildings"
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Shingle Roofs" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="Man using a nail gun to nail down roof tiles"
            imageURL={data.ResidentialServices.childImageSharp.gatsbyImageData}
            titleText="Shingle Roof Repair for the Greater Phoenix Area"
          >
            <p>
              Legacy is a complete repair and care roofing company for shingle
              roofs of all material types. The vast majority of residential
              roofs are asphalt shingles. As a GAF Certified Master Installer,
              you can have the peace of mind that Legacy will repair your roof
              correctly.
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Repairs and Maintenance for Shingle Roofs
            </Heading>

            <p>
              There are several types of covering or ceiling deficiencies you
              can identify without having to get on your roof.
            </p>

            <p>What to consider for your shingle roof:</p>

            <ol>
              <li>
                Identify areas of concern on your shingle roof: valleys,
                penetrations (vents), AC Units, and any equipment fastened to
                the roof can all be areas of concern.
              </li>
              <li>
                Does your roof have any foliage or tree overgrowth that could
                cause water blockage?
              </li>
              <li>Are shingles blown up or off from heavy winds? </li>
              <li>
                Are there pockmarks from hail damage, or signs of excessive wear
                from weather?
              </li>
            </ol>

            <p>
              Shingle roofs typically need less care and maintenance compared to
              other roof materials. Shingle material is typically hardy, and -
              if installed properly - is highly resistant to damage. However,
              this does not mean that areas around penetrations, roof-mounted
              equipment like solar panels or AC units, and vents shouldn’t be
              inspected periodically and maintained. In the Arizona sun, those
              areas can dry out, crack and become a potential point for water
              leaks.
            </p>

            <p>
              Regular inspections (every few years) can help prevent future
              leaks, but if an area does leak, it should be addressed
              immediately. As water finds a route of access, it is likely that
              the area will become worse with time. It is important to address
              the leak as soon as possible to prevent further damage to the roof
              or the interior of the home, and ensure things like black mold
              don’t further exacerbate the leak.
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Shingle Roof Materials
            </Heading>

            <p>
              Shingle roofing products can actually come in several different
              material formats: wood (sometimes called shakes), slate, asphalt
              shingles (the most common), and even some types of metal or
              plastic. These types of materials are all used to cover pitched
              roofs and wick water to ensure a dry and comfortable living
              environment.
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "shingle-roofs-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    BuildingsIcon: file(relativePath: { eq: "buildings.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ResidentialServices: file(
      relativePath: { eq: "man-holding-a-nail-gun.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default ShingleRoofs;
